// Carousel is plugin already loaded
// Doc: https://owlcarousel2.github.io/OwlCarousel2/


$(document).ready(function(){

var reviewsWrap = $('.reviews-wrap');

if (reviewsWrap.length) {
    reviewsWrap.find( '.reviews-cont' ).owlCarousel({
        responsive: {
            0: {
              items: 1,
              nav: false,
              dots: true
            },
            767: {
                items: 2
            },
            1576: {
              items: 2,
              nav: true,
              dots: false
            }
        },
        slideSpeed: 200,
        lazyLoad: false
    });
  }



  var blogPostswrap = $('.blog-posts-wrap');

  if (blogPostswrap.length) {
jQuery(window).scroll(function() {
      if (
      jQuery(window).scrollTop() + jQuery(window).height() >=
        jQuery(".featured-categories-wrap").offset().top &&
        blogPostswrap.html().length < 20
      ) {
          calls = {
              blog: ".blog-posts-wrap",
              // instagram: ".instagram"
              // 'reco' : '.recommendation'
          };
          // call blog
          jQuery.each(calls, function(i, v) {
              jQuery.ajax({
                  url: "/ajax_home/" + i,
                  method: "GET",
                  beforeSend: function() {
                      jQuery(v).html("<img src='img/loading.svg'>");
                  },

                  success: function(response) {
                      text_copy = response;
                      jQuery(v).html(response);
                      blogPostswrap.find( '.blog-posts-cont' ).owlCarousel({
                          responsive: {
                              0: {
                                  items: 1,
                                  nav: false,
                                  dots: true
                              },
                              600: {
                                  items: 2,
                              },
                              1024: {
                                  items: 3,
                              },
                              1576: {
                                nav: true,
                                dots: false
                              }
                          },
                          slideSpeed: 200,
                          lazyLoad: false
                      });
                  }
              });
          });
      }
  });
  }



// Flip through carousel for three products
// Initializing variables of featured products area carousel of three items. 


$('.products-slide-wrap').prepend('<div id="products-slide-version-2"><div class="js-slide prev"></div><div class="js-slide selected"></div><div class="js-slide next"></div></div>');

  var arrOfClasses = ['prev', 'selected', 'next'];
  var rightArr = $('.slide-right-arrow', $('.featured-products'));
  var leftArr = $('.slide-left-arrow', $('.featured-products'));
  var nodeCollection = $('.js-slide', $('#products-slide-version-2'));
  var dataCollection = Array.prototype.slice.call($('.js-slide', $('#products-slide'))); 
  var infoCollection = $('.product-info', $('.featured-products-content'));
  

//   Synchronize with DOM
  function domSyncronize(){
    infoCollection.hide();
    infoCollection.css({'opacity': '0'});

    nodeCollection.each(function(key, node){
        arrOfClasses.forEach(function(c){
            $(node).removeClass(c)
        }); 
          $(node).addClass(arrOfClasses[key]);

          if (arrOfClasses[key] === 'selected') {
        
          var arrStr = $('.js-slide.selected a').attr('href').split('/');
          var id = arrStr[arrStr.length - 1];
          var nodeToShow = $(infoCollection).filter(function(i, el){ return el.dataset.id === id; });
          nodeToShow.show();
          setTimeout(function() {nodeToShow.css({'opacity': '1'})}); 
        }
    })
  }

//   Slide forward
  function moveNext(arr){
    // Logic of Data collection being manipulated for the slide
    dataCollection.push(dataCollection.shift()); 
    var node = $('.js-slide.prev');
    setTimeout(function(){node.html(dataCollection[2].innerHTML);}, 300);

    // Logic of classes manipulation to slide the data previously provided
    arr.unshift(arr.pop());
  }

//   Slide backwards
  function movePrev(arr){
      // Logic of Data collection being manipulated for the slide
    dataCollection.unshift(dataCollection.pop());
    var node = $('.js-slide.next');
    setTimeout(function(){node.html(dataCollection[0].innerHTML);}, 300);

      // Logic of classes manipulation to slide the data previously provided
    arr.push(arr.shift());
  }

//   Event listeners on click
  rightArr.click(function(){
    moveNext(arrOfClasses);
    domSyncronize();
  })

  leftArr.click(function(){
    movePrev(arrOfClasses);
    domSyncronize();
  })

//  Slides initialization on onload
  nodeCollection.each(function(index, node){
    $(node).html(dataCollection[index].innerHTML);
  })
  domSyncronize();


  // Swiping feature of slider
  var touchstartX = 0;
  var touchendX = 0;
  var parentNode = document.querySelector('.products-slide-wrap');

  function handleGesure() {
    if (touchendX < touchstartX) {
      rightArr.click();
    }
    if (touchendX > touchstartX) {
      leftArr.click();
    } 
  }

  parentNode.addEventListener('touchstart', function(e) {
      touchstartX = e.changedTouches[0].screenX;
  })

  parentNode.addEventListener('touchend', function(e) {
      touchendX = e.changedTouches[0].screenX;
      handleGesure();
  })
});